import qs from "qs";
import { pick } from 'lodash';
import iframeResize from "iframe-resizer/js/iframeResizer";
import throttle from 'lodash/throttle';

const clientId = process.env.EB_REACT_APP_KEYCLOAK_CLIENT_ID;

const BACK_URI = "backUri";

let configuration = {};
let nestedObjectString = "";
if (window.BOKAMERA && window.BOKAMERA.configuration) {
  configuration = {
    ...window.BOKAMERA.configuration,
    ...pick(qs.parse(window.location.search, { ignoreQueryPrefix: true }), [
      "firstname",
      "lastname",
      "email",
      "phone",
    ]),
  };  

  if(!configuration.targetOrigin) {
    configuration._targetOrigin = window.location.origin;
  }

  for (const key in configuration) {
    if (configuration.hasOwnProperty(key)) {
      const value = configuration[key];
      // qs don't support nested object with the { arrayFormat: 'comma' }
      // option, so we are handling it
      if (
        value &&
        value[0] &&
        typeof value[0] === "object" &&
        Array.isArray(value)
      ) {
        nestedObjectString = `&${key}=${encodeURI(JSON.stringify(value))}`;
        delete configuration[key];
      }
    }
  }
}

// global methods
window.restartEmbedBookingApp = () => {
  const container = document.getElementById("bokamera-embedded");

  if(container.getElementsByTagName('iframe')[0]) {
    container.getElementsByTagName('iframe')[0].remove();
    
    removeOrchestorScript();
    addOrchestratorScript(startApp);
  }
}
window.qs = qs;
// embed logic
function createIframe() {
  const iframe = document.createElement("iframe");
  let iframeSrcUrl = new URL(process.env.EB_REACT_APP_BASE_URL);

  const codeParam = window.location.hash && qs.parse(window.location.hash, {ignoreQueryPrefix: true})['code'] ? window.location.hash : '';

  const searchParams = Object.entries(qs.parse(window.location.search, { ignoreQueryPrefix: true })).reduce((acc, curr) => {
    if(curr[0].charAt(0) === '_') {
      acc[curr[0]] = curr[1];
    }
    return acc;
  }, {});

  if(codeParam) {
    iframeSrcUrl.hash = `#/auth?${window.location.hash ? window.location.hash.substring(1) : ''}&${qs.stringify(searchParams)}`;
  }
  
  iframeSrcUrl.search = `${qs.stringify(configuration, { arrayFormat: "comma" })}${nestedObjectString}`;

  if(!iframeSrcUrl.searchParams.get(BACK_URI)) {
    iframeSrcUrl.searchParams.append(
      BACK_URI,
      `${window.location.origin}${window.location.pathname}`
    );
  }
  

  iframe.setAttribute("src", iframeSrcUrl.toString());
  iframe.setAttribute("frameBorder", "0");
  iframe.setAttribute("scrolling", "no");
  iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-forms allow-top-navigation allow-top-navigation-by-user-activation allow-popups allow-modals');
  iframe.style.width = "100%";
  iframe.style.minHeight = '640px';

  return iframe;
}

function addOrchestratorScript(cb) {
  const scriptId = 'orchestrator';
  const redirectAuthScript = document.createElement("script");
  const appEnvironment = process.env.EB_REACT_APP_ENVIRONMENT;
  const appProfileUrl = process.env.EB_REACT_APP_PROFILE_URL;
  redirectAuthScript.type = "text/javascript";
  redirectAuthScript.id = scriptId;
  redirectAuthScript.src = `${appProfileUrl}/orchestrator.${appEnvironment}.js`;
  redirectAuthScript.onload = () => {
    cb()
  };

  if(!document.getElementById(scriptId)) {
    document.head.appendChild(redirectAuthScript);
  } else {
    document.getElementById(scriptId).remove();
    document.head.appendChild(redirectAuthScript);
  }
}

function removeOrchestorScript() {
  const scriptId = 'orchestrator';
  document.getElementById(scriptId).remove();
  
}


function startApp() {
  const globalStyle = document.createElement('style');
  globalStyle.innerHTML = `
    html { scroll-behavior: smooth; }

    #bokamera-embedded {
      clear: both;
    }
  `;
  
  const container = document.getElementById("bokamera-embedded");

  if(!container) {
    console.warn('No container, with id bokamera-embedded, provided.')
    return;
  }

  if(container.querySelector('iframe')) {
    container.innerHTML = '';
  }
  // Grab styles
  const appStyle = container.querySelector('style');
  const appStyleHtml = appStyle && appStyle.innerHTML;
  const iframe = createIframe();

  container.childNodes.forEach(c => c.remove());
  container.appendChild(iframe);
  container.appendChild(globalStyle);

  const onScroll = () => {
    const bottomDifference = getBottomDifference(iframeResizers[0]);
    const isCloseToTop = iframeResizers[0].getBoundingClientRect().height - bottomDifference < 300;
      
    if(!isCloseToTop) {
      iframeResizers[0].iFrameResizer.sendMessage({
        type: "SCROLL",
        payload: bottomDifference,
      });
    }
  }

  const iframeResizers = iframeResize(
    {
      onResized: (ev) => {
        window.postMessage({ iframeResized: true })
        
        onScroll();
      },
      onInit: () => {
        window.addEventListener('scroll', throttle(onScroll, 20 ));
        iframeResizers[0].iFrameResizer.sendMessage({ type: 'INJECT_APP_CSS', payload: appStyleHtml });
        
        const appState = sessionStorage.getItem(clientId);
        if(appState) {
          iframeResizers[0].iFrameResizer.sendMessage({ type: 'REHYDRATE_STATE', payload:  appState});
        }

        if(window.performance && window.performance.navigation && window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD) {
          iframeResizers[0].iFrameResizer.sendMessage({ type: 'CLEAR_KEYCLOAK_LOADING', payload: appStyleHtml });
        }
      }, 
      heightCalculationMethod: "taggedElement",
      checkOrigin: false,
    },
    "#bokamera-embedded > iframe"
  );
}

addOrchestratorScript(startApp);

function getBottomDifference(element) {
  const rect = element.getBoundingClientRect();
  return (    
      rect.bottom - (window.innerHeight || document.documentElement.clientHeight)
  );
}
